import { styled } from '@automata/mui-theme';
import { ElementType, ReactNode } from 'react';

// https://benmyers.dev/blog/native-visually-hidden/
const VisuallyHiddenEl = styled('span')`
  &:not(:focus):not(:active) {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export interface VisuallyhiddenProps {
  children: ReactNode;
  element?: ElementType;
  id?: string;
}

export const VisuallyHidden = ({
  children,
  element = 'span',
  id,
}: VisuallyhiddenProps) => (
  <VisuallyHiddenEl id={id} as={element}>
    {children}
  </VisuallyHiddenEl>
);
