import { ReactElement } from 'react';
import Image from 'next/image';
import { styled } from '@automata/mui-theme';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '../Typography';

export type EmptyPlaceholderProps = {
  title?: string;
  subTitle?: string;
  alt?: string;
  img?: string;
};

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 845px;
  min-height: 300px;
  margin: 0 auto;
  padding: 10px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 80px 10px 100px;
  }
`;

export const EmptyPlaceholder = ({
  title,
  subTitle,
  alt = 'no items to display',
  img = '/empty-page-illustration.svg',
}: EmptyPlaceholderProps): ReactElement => (
  <Wrapper>
    <Image src={img} alt={alt} fill />
    {(title || subTitle) && (
      <Stack gap={1} maxWidth="30em">
        {title && <Typography fontWeight="bold">{title}</Typography>}
        {subTitle && <Typography>{subTitle}</Typography>}
      </Stack>
    )}
  </Wrapper>
);
