import { env as ENV } from '../environment';

type UnknownObject = Record<string, unknown> | {};
type WithPayload<T extends UnknownObject> = T & { payload: string | Error };
type WithDetails<T extends UnknownObject> = T & { details: string };
type WithMessage<T extends UnknownObject> = T & { message: string };
type WithStackMessage<T extends UnknownObject> = T & {
  stack: { message: string };
};
export const hasPayload = <T extends UnknownObject>(
  obj: T
): obj is WithPayload<T> => 'payload' in obj;
export const hasDetails = <T extends UnknownObject>(
  obj: T
): obj is WithDetails<T> =>
  'details' in obj && typeof obj.details === 'string' && obj.details !== '';
export const hasMessage = <T extends UnknownObject>(
  obj: T
): obj is WithMessage<T> =>
  'message' in obj && typeof obj.message === 'string' && obj.message !== '';
export const hasStackMessage = <T extends UnknownObject>(
  obj: T
): obj is WithStackMessage<T> => {
  if (!('stack' in obj)) return false;
  if (typeof obj.stack !== 'object') return false;
  if (obj.stack === null) return false;
  return (
    'message' in obj.stack &&
    typeof obj.stack.message === 'string' &&
    obj.stack.message !== ''
  );
};

/**
 * Returns the error message from an error object or a fallback message
 * @param error
 * @param fallback a string to return if the error does not have a message
 * @returns a string containing the error message
 */
export const getErrorMessage = (
  error: unknown,
  fallback: string,
  env = ENV('NEXT_PUBLIC_ENV')
): string => {
  // eslint-disable-next-line no-console
  if (env !== 'prod') console.error(error);

  if (!error) return fallback;
  if (typeof error === 'string') return error;
  if (hasPayload(error)) return getErrorMessage(error.payload, fallback);
  if (hasDetails(error)) return error.details;
  if (hasMessage(error)) return error.message;
  if (hasStackMessage(error)) return error.stack.message;
  return fallback;
};

export const getErrorStatus = (error: unknown) => {
  if (error && typeof error === 'object' && 'status' in error) {
    if (typeof error.status === 'number') return error.status;
    if (typeof error.status === 'string') return parseInt(error.status, 10);
  }
  return 500;
};

export const isAbortError = (error: unknown) => {
  if (!(error instanceof DOMException)) return false;
  if (error.name !== 'AbortError') return false;
  return true;
};
