import { defaultTheme } from '@automata/mui-theme';
import { KeyValuePair } from '../KeyValuePair';
import { Typography } from '../Typography';
import Divider from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

export interface BannerBlockProps {
  title?: string;
  bgColor: StackProps['bgcolor'];
  items: Array<{
    keyString: string;
    valueElement: ReactNode;
  }>;
  flexGrow?: number;
}

export const BannerBlock = ({
  title,
  items,
  bgColor,
  flexGrow = 1,
}: BannerBlockProps): JSX.Element => {
  return (
    <Stack>
      {title && (
        <Box mb={1}>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      )}
      <Stack
        bgcolor={bgColor}
        p={1}
        borderRadius={1}
        direction="row"
        flexGrow={flexGrow}
      >
        {items.map(({ keyString, valueElement }, i) => (
          <Stack key={keyString} direction="row" flexShrink={0}>
            {i > 0 && (
              <Box mx={2}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 2, mt: 2, color: defaultTheme.palette.divider }}
                />
              </Box>
            )}
            <KeyValuePair
              keyElement={
                <Typography variant="overline" color="textSecondary" noWrap>
                  {keyString}
                </Typography>
              }
              valueElement={valueElement}
              name={keyString}
              gap={1}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
