import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const message =
  'LINQ Software is currently updating. All run controls are disabled until the update is complete.';

export const MaintenanceBanner = (): JSX.Element => {
  return (
    <Alert severity="info">
      <AlertTitle>
        <strong>Upcoming software update</strong>
      </AlertTitle>
      {message}
    </Alert>
  );
};
