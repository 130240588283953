import { useState } from 'react';
import {
  castTimeBreakdownStrings,
  getTimeBreakdownStrings,
  getTotalSeconds,
} from './utils';
import type { TimeBreakdownStrings } from './types';

export const useDurationPickerField = (
  persistValue: (newTotalSeconds: number) => void,
  totalSeconds?: number | null
) => {
  const [breakdown, setBreakdown] = useState<TimeBreakdownStrings>(
    getTimeBreakdownStrings(totalSeconds)
  );

  const handleBlur = () => {
    const newTotalSeconds = getTotalSeconds(
      castTimeBreakdownStrings(breakdown)
    );
    if (newTotalSeconds === totalSeconds) return;
    persistValue(newTotalSeconds);
  };
  const handleUpdateTime = (patch: Partial<TimeBreakdownStrings>) => {
    setBreakdown((prev) => ({ ...prev, ...patch }));
  };

  return { breakdown, handleBlur, handleUpdateTime };
};
