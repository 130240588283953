import {
  PaletteColorOptions,
  SimplePaletteColorOptions,
  CategoricalPaletteOptions,
} from '@mui/material/styles/createPalette';

type Semantic = {
  error: SimplePaletteColorOptions;
  warning: SimplePaletteColorOptions;
  success: SimplePaletteColorOptions;
  info: SimplePaletteColorOptions;
};
type ButtonShades = {
  [key: string]: {
    focusedText: string;
    hover?: string;
    focused?: string;
    outline?: {
      default: string;
      hoverBg: string;
      hoverText: string;
      hoverOutline: string;
      focusedBg: string;
      focusedText: string;
      focusedOutline: string;
    };
  };
};

// TODO: address opacity values
export const common = {
  light: {
    main: '#000000',
    38: '#9E9E9E',
    60: '#666666',
    87: '#212121',
  },
  dark: {
    main: '#FFFFFF',
    38: '#9E9E9E',
    60: '#666666',
    87: '#212121',
  },
};

export const grey = {
  50: '#FFFFFF',
  100: '#F9F9F9',
  200: '#F2F2F2',
  300: '#E4E4E4',
  400: '#CDCDCD',
  500: '#AEAEAE',
  600: '#898989',
  700: '#626262',
  800: '#414141',
  900: '#292929',
  contrastText: common.dark.main,
} satisfies PaletteColorOptions;

export const primary = {
  50: '#F4F9FF',
  100: '#D8E6FC',
  200: '#BCD5F8',
  300: '#8CB5F0',
  400: '#6198E5',
  500: '#3E7DD6',
  600: '#2264C2',
  700: '#0F4DA8',
  800: '#0A3B8C',
  900: '#072A6A',
  hover: '#0F4DA80A',
  selected: 'rgba(15, 77, 168, 0.08)',
  focused: 'rgba(15, 77, 168, 0.30)',
  enabled: 'rgba(15, 77, 168, 0.50)',
} satisfies PaletteColorOptions;

export const secondary = {
  50: '#FFF3F7',
  100: '#FDD3E1',
  200: '#FBB4CC',
  300: '#F87CA8',
  400: '#F2558D',
  500: '#EA3A6C',
  600: '#DE2A6C',
  700: '#D1185C',
  800: '#BD0C4D',
  900: '#9E073F',
  hover: 'rgba(222, 42, 108, 0.4)',
  selected: 'rgba(222, 42, 108, 0.08)',
  focused: 'rgba(222, 42, 108, 0.30)',
  enabled: 'rgba(222, 42, 108, 0.50)',
} satisfies PaletteColorOptions;

export const accent1 = {
  50: '#FCFFE6',
  100: '#F9FFC4',
  200: '#F6FFA1',
  300: '#F0FF65',
  400: '#EBFE2E',
  500: '#E5FB00',
  600: '#D9F300',
  700: '#CBE900',
  800: '#BBDD00',
  900: '#A9CE00',
  contrastText: common.dark.main,
} satisfies PaletteColorOptions;

export const accent2 = {
  50: '#FFF9EB',
  100: '#FFF2CE',
  200: '#FFEBB1',
  300: '#FFDA7B',
  400: '#FFC94B',
  500: '#FFB622',
  600: '#FFA100',
  700: '#F98F00',
  800: '#F17B00',
  900: '#E96500',
  contrastText: common.dark.main,
} satisfies PaletteColorOptions;

export const categorical = {
  darkBlue: {
    main: '#032F58',
    25: '#032F583F',
    50: '#8197AC',
    readableName: 'dark blue',
  },
  plateGreen: {
    main: '#97B1AB',
    25: '#97B1AB3F',
    50: '#CBD8D5',
    readableName: 'plate green',
  },
  marineGreen: {
    main: '#14C197',
    25: '#14C1973F',
    50: '#89E0CB',
    readableName: 'marine green',
  },
  winterGreen: {
    main: '#197959',
    25: '#1979593F',
    50: '#8CBCAC',
    readableName: 'winter green',
  },
  swimBlue: {
    main: '#B0FFF9',
    25: '#B0FFF93F',
    50: '#D8FFFC',
    readableName: 'swim blue',
  },
  greyBlue: {
    main: '#326F9C',
    25: '#326F9C3F',
    50: '#99B7CD',
    readableName: 'grey blue',
  },
  skyBlue: {
    main: '#54B2FC',
    25: '#54B2FC3F',
    50: '#A9D9FE',
    readableName: 'sky blue',
  },
  softPink: {
    main: '#F996F1',
    25: '#F996F13F',
    50: '#FCCAF8',
    readableName: 'soft pink',
  },
  cerise: {
    main: '#B03D8D',
    25: '#B03D8D3F',
    50: '#D89EC6',
    readableName: 'cerise',
  },
  terracotta: {
    main: '#956891',
    25: '#9568913F',
    50: '#CAB4C8',
    readableName: 'terracotta',
  },
  candyPink: {
    main: '#EB04DC',
    25: '#EB04DC3F',
    50: '#F581EE',
    readableName: 'candy pink',
  },
  spotRed: {
    main: '#FD048F',
    25: '#FD048F3F',
    50: '#FE81C7',
    readableName: 'spot red',
  },
} as const satisfies CategoricalPaletteOptions;

export const semantic: Semantic = {
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    hover: 'rgba(211, 47, 47, 0.4)',
    selected: 'rgba(211, 47, 47, 0.12)',
    focused: 'rgba(211, 47, 47, 0.30)',
    enabled: 'rgba(211, 47, 47, 0.50)',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    hover: 'rgba(237, 108, 2, 0.4)',
    selected: 'rgba(237, 108, 2, 0.12)',
    focused: 'rgba(237, 108, 2, 0.30)',
    enabled: 'rgba(237, 108, 2, 0.50)',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    hover: 'rgba(46, 125, 50, 0.4)',
    selected: 'rgba(46, 125, 50, 0.12)',
    focused: 'rgba(46, 125, 50, 0.30)',
    enabled: 'rgba(46, 125, 50, 0.50)',
  },
  info: {
    main: '#3E7DD6',
    dark: '#0A3B8C',
    light: '#BCD5F8',
    hover: 'rgba(34, 100, 194, 0.4)',
    selected: 'rgba(34, 100, 194, 0.12)',
    focused: 'rgba(34, 100, 194, 0.30)',
    enabled: 'rgba(34, 100, 194, 0.50)',
  },
};

export const interactions = {
  hover: 'rgba(0, 0, 0, 0.4)',
  selected: 'rgba(0, 0, 0, 0.8)',
  disabledBg: 'rgba(0, 0, 0, 0.12)',
  disabledParts: 'rgba(0, 0, 0, 0.26)',
  focused: 'rgba(0, 0, 0, 0.30)',
  active: 'rgba(0, 0, 0, 0.54)',
};

export const buttonShades: ButtonShades = {
  primary: {
    hover: primary[800],
    focused: primary.focused,
    focusedText: primary[600],
  },
  secondary: {
    hover: secondary[900],
    focused: secondary.focused,
    focusedText: secondary[600],
  },
  error: {
    hover: semantic.error.dark,
    focused: semantic.error.focused,
    focusedText: semantic.error.main,
  },
  warning: {
    hover: semantic.warning.dark,
    focused: semantic.warning.focused,
    focusedText: semantic.warning.main,
  },
  success: {
    hover: semantic.success.dark,
    focused: semantic.success.focused,
    focusedText: semantic.success.main,
  },
  info: {
    hover: semantic.info.dark,
    focused: semantic.info.focused,
    focusedText: semantic.info.main,
  },
  grey: {
    hover: grey[200],
    focused: interactions.focused,
    focusedText: '#000000',
    outline: {
      default: common.light[87],
      hoverBg: primary.hover,
      hoverText: common.light[87],
      hoverOutline: primary.focused,
      focusedBg: 'none',
      focusedText: common.light[87],
      focusedOutline: interactions.focused,
    },
  },
};
