import { useState, useCallback } from 'react';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { defaultTheme } from '@automata/mui-theme';
import { SxStyles } from '../types';

const styles = {
  menuOpen: {
    backgroundColor: defaultTheme.palette.grey[100],
  },
  menuClosed: {
    backgroundColor: 'inherit',
  },
} satisfies SxStyles;

export interface ContextMenuProps {
  buttonId?: string;
  menuOptions: string[];
  resourceId?: string;
  resourceType?: string;
  selectResource?: (id: string) => void;
  selectAction: (action: string, anchorEl: HTMLElement | null) => void;
  children?: JSX.Element;
}

export const ContextMenu = ({
  buttonId,
  menuOptions,
  resourceId,
  resourceType,
  selectResource,
  selectAction,
  children,
}: ContextMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      if (selectResource && resourceId) selectResource(resourceId);
    },
    [selectResource, resourceId]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleActionSelect = useCallback(
    (el: HTMLButtonElement | null, action: string) => {
      selectAction(action, el);
      handleClose();
    },
    [selectAction, handleClose]
  );

  return (
    <>
      <IconButton
        id={buttonId}
        onClick={handleClick}
        aria-label="context menu toggle"
        sx={!!anchorEl ? styles.menuOpen : styles.menuClosed}
      >
        <MoreVertOutlined />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {menuOptions.map((option) => {
          return (
            <MenuItem
              key={option}
              onClick={() => {
                handleActionSelect(anchorEl, option);
              }}
            >
              {resourceType ? `${option} ${resourceType}` : option}
            </MenuItem>
          );
        })}
        {children ? <Box sx={{ width: '277px' }}>{children}</Box> : null}
      </Popover>
    </>
  );
};
