import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { BoxProps } from '@mui/material/Box';

export type InfoIconPopoverProps = {
  infoPopover: JSX.Element;
  infoPopoverMaxWidth?: BoxProps['maxWidth'];
  infoPopoverAnchorOrigin?: PopoverProps['anchorOrigin'];
  infoPopoverTransformOrigin?: PopoverProps['transformOrigin'];
};

export const InfoIconPopover = ({
  infoPopover,
  infoPopoverMaxWidth = 400,
  infoPopoverAnchorOrigin,
  infoPopoverTransformOrigin,
}: InfoIconPopoverProps): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        aria-label="info popover icon"
        onClick={handleClick}
      >
        <InfoOutlinedIcon fontSize="inherit" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={infoPopoverAnchorOrigin}
        transformOrigin={infoPopoverTransformOrigin}
      >
        <Box maxWidth={infoPopoverMaxWidth}>{infoPopover}</Box>
      </Popover>
    </>
  );
};
