import { useState } from 'react';
import { Modal } from '../Modal';
import {
  DataGridPro,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { Icon } from '../../../workflow-builder/src/components/customIcons/misc';
import Box from '@mui/material/Box';

export type LabwarePositionInfo = {
  id: string;
  name: string;
  startLocation: {
    instrument: string;
    slot: number;
  };
}[];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const columns: GridColDef<LabwarePositionInfo[number]>[] = [
  { field: 'name', headerName: 'Labware name', flex: 1 },
  { field: 'id', headerName: 'Labware ID', flex: 2 },
  {
    field: 'startLocation',
    headerName: 'Start location [Slot #]',
    flex: 1,
    valueGetter: (_, row) =>
      `${row.startLocation.instrument} [${row.startLocation.slot}]`,
  },
];

export const LabwarePositionModal = ({
  data,
  open,
  onClose,
}: {
  data: LabwarePositionInfo;
  open: boolean;
  onClose: () => void;
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  return (
    <Modal
      title="Labware"
      titleIcon={<Icon iconType="labware" />}
      open={open}
      onClose={onClose}
      maxWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '60vh',
        },
      }}
    >
      <Box
        sx={{
          height: 500,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGridPro
          sx={{
            flex: 1,
            overflow: 'auto',
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'auto',
            },
          }}
          hideFooterRowCount
          rows={data}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 100 },
            },
          }}
          disableColumnMenu
        />
      </Box>
    </Modal>
  );
};
