import { createSvgIcon } from '@automata/mui-theme';
export const processDataIcon = createSvgIcon(
  <svg viewBox="0 0 96 97" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 52H16V78H13V52Z" />
    <path d="M6 71.1213L8.12132 69L16.6066 77.4853L14.4853 79.6066L6 71.1213Z" />
    <path d="M14.5 79.6067L12.3787 77.4854L20.864 69.0001L22.9853 71.1214L14.5 79.6067Z" />
    <path d="M13 46H16V49H13V46Z" />
    <path d="M13 41H16V44H13V41Z" />
    <path d="M13 36H16V39H13V36Z" />
    <path d="M80 46H83V20H80V46Z" />
    <path d="M73 26.8787L75.1213 29L83.6066 20.5147L81.4853 18.3934L73 26.8787Z" />
    <path d="M81.5 18.3933L79.3787 20.5146L87.864 28.9999L89.9853 26.8786L81.5 18.3933Z" />
    <path d="M80 52H83V49H80V52Z" />
    <path d="M80 57H83V54H80V57Z" />
    <path d="M80 62H83V59H80V62Z" />
    <path d="M26 18.3478L32 11V24H21L26 18.3478Z" />
    <path d="M51 21V17C51 15.3431 49.6569 14 48 14H32.3299C31.3632 14 30.4558 14.4658 29.8923 15.2513L24.5624 22.6809C24.1967 23.1907 24 23.8023 24 24.4296V49.0002C24 50.3064 24.8348 51.4176 26 51.8295V54.9172C23.1623 54.4411 21 51.9732 21 49.0002V24.4296C21 23.1749 21.3934 21.9517 22.1248 20.9322L27.4547 13.5026C28.5817 11.9316 30.3966 11 32.3299 11H48C51.3137 11 54 13.6863 54 17V21H51Z" />
    <path d="M35.3636 34.1739L43 24V42H29L35.3636 34.1739Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9409 24C40.9459 24 39.0814 24.9916 37.9661 26.6457L30.0252 38.4227C29.357 39.4137 29 40.5818 29 41.777V79C29 82.3137 31.6863 85 35 85H66C69.3137 85 72 82.3137 72 79V30C72 26.6863 69.3137 24 66 24H42.9409ZM69 79V30C69 28.3431 67.6569 27 66 27H42.9409C41.9434 27 41.0112 27.4958 40.4535 28.3228L32.5126 40.0999C32.1785 40.5954 32 41.1794 32 41.777V79C32 80.6569 33.3431 82 35 82H66C67.6569 82 69 80.6569 69 79Z"
    />
    <path d="M40.6025 58H38.8188V50.9097L36.4829 51.7476V50.2114L40.5073 48.7578H40.6025V58Z" />
    <path d="M50.105 54.4834C50.105 55.0801 50.0352 55.6069 49.8955 56.064C49.7601 56.5168 49.5633 56.8955 49.3052 57.2002C49.047 57.5091 48.7318 57.7419 48.3594 57.8984C47.987 58.055 47.568 58.1333 47.1025 58.1333C46.6413 58.1333 46.2244 58.055 45.8521 57.8984C45.4797 57.7419 45.1644 57.5091 44.9062 57.2002C44.6439 56.8955 44.4408 56.5168 44.2969 56.064C44.1572 55.6069 44.0874 55.0801 44.0874 54.4834V52.2744C44.0874 51.6777 44.1572 51.153 44.2969 50.7002C44.4365 50.2432 44.6375 49.8602 44.8999 49.5513C45.158 49.2466 45.4712 49.0159 45.8394 48.8594C46.2118 48.7028 46.6286 48.6245 47.0898 48.6245C47.5553 48.6245 47.9743 48.7028 48.3467 48.8594C48.7191 49.0159 49.0365 49.2466 49.2988 49.5513C49.557 49.8602 49.7559 50.2432 49.8955 50.7002C50.0352 51.153 50.105 51.6777 50.105 52.2744V54.4834ZM45.8647 53.7217L48.3276 51.8872C48.3192 51.5402 48.2811 51.244 48.2134 50.9985C48.1499 50.7489 48.0547 50.55 47.9277 50.4019C47.8304 50.2834 47.7098 50.1966 47.5659 50.1416C47.4263 50.0824 47.2676 50.0527 47.0898 50.0527C46.8909 50.0527 46.7153 50.0908 46.563 50.167C46.4149 50.2389 46.29 50.349 46.1885 50.4971C46.0784 50.6536 45.9959 50.8568 45.9409 51.1064C45.8901 51.3561 45.8647 51.6523 45.8647 51.9951V53.0171C45.8647 53.2202 45.8647 53.3556 45.8647 53.4233C45.8647 53.491 45.8647 53.5905 45.8647 53.7217ZM48.3276 54.75V53.6772C48.3276 53.5376 48.3276 53.436 48.3276 53.3726C48.3276 53.3049 48.3276 53.2096 48.3276 53.0869L45.8647 54.915C45.8732 55.1943 45.9007 55.4419 45.9473 55.6577C45.998 55.8735 46.0679 56.0534 46.1567 56.1973C46.2583 56.3708 46.3874 56.4998 46.5439 56.5845C46.7005 56.6691 46.8867 56.7114 47.1025 56.7114C47.3014 56.7114 47.4771 56.6755 47.6294 56.6035C47.7817 56.5273 47.9087 56.4131 48.0103 56.2607C48.116 56.0999 48.1943 55.8947 48.2451 55.645C48.3001 55.3911 48.3276 55.0928 48.3276 54.75Z" />
    <path d="M56.2178 58H54.4341V50.9097L52.0981 51.7476V50.2114L56.1226 48.7578H56.2178V58Z" />
    <path d="M64.0254 58H62.2417V50.9097L59.9058 51.7476V50.2114L63.9302 48.7578H64.0254V58Z" />
    <path d="M42.2974 67.4834C42.2974 68.0801 42.2275 68.6069 42.0879 69.064C41.9525 69.5168 41.7557 69.8955 41.4976 70.2002C41.2394 70.5091 40.9242 70.7419 40.5518 70.8984C40.1794 71.055 39.7604 71.1333 39.2949 71.1333C38.8337 71.1333 38.4168 71.055 38.0444 70.8984C37.672 70.7419 37.3568 70.5091 37.0986 70.2002C36.8363 69.8955 36.6331 69.5168 36.4893 69.064C36.3496 68.6069 36.2798 68.0801 36.2798 67.4834V65.2744C36.2798 64.6777 36.3496 64.153 36.4893 63.7002C36.6289 63.2432 36.8299 62.8602 37.0923 62.5513C37.3504 62.2466 37.6636 62.0159 38.0317 61.8594C38.4041 61.7028 38.821 61.6245 39.2822 61.6245C39.7477 61.6245 40.1667 61.7028 40.5391 61.8594C40.9115 62.0159 41.2288 62.2466 41.4912 62.5513C41.7493 62.8602 41.9482 63.2432 42.0879 63.7002C42.2275 64.153 42.2974 64.6777 42.2974 65.2744V67.4834ZM38.0571 66.7217L40.52 64.8872C40.5116 64.5402 40.4735 64.244 40.4058 63.9985C40.3423 63.7489 40.2471 63.55 40.1201 63.4019C40.0228 63.2834 39.9022 63.1966 39.7583 63.1416C39.6187 63.0824 39.46 63.0527 39.2822 63.0527C39.0833 63.0527 38.9077 63.0908 38.7554 63.167C38.6073 63.2389 38.4824 63.349 38.3809 63.4971C38.2708 63.6536 38.1883 63.8568 38.1333 64.1064C38.0825 64.3561 38.0571 64.6523 38.0571 64.9951V66.0171C38.0571 66.2202 38.0571 66.3556 38.0571 66.4233C38.0571 66.491 38.0571 66.5905 38.0571 66.7217ZM40.52 67.75V66.6772C40.52 66.5376 40.52 66.436 40.52 66.3726C40.52 66.3049 40.52 66.2096 40.52 66.0869L38.0571 67.915C38.0656 68.1943 38.0931 68.4419 38.1396 68.6577C38.1904 68.8735 38.2603 69.0534 38.3491 69.1973C38.4507 69.3708 38.5798 69.4998 38.7363 69.5845C38.8929 69.6691 39.0791 69.7114 39.2949 69.7114C39.4938 69.7114 39.6694 69.6755 39.8218 69.6035C39.9741 69.5273 40.1011 69.4131 40.2026 69.2607C40.3084 69.0999 40.3867 68.8947 40.4375 68.645C40.4925 68.3911 40.52 68.0928 40.52 67.75Z" />
    <path d="M50.105 67.4834C50.105 68.0801 50.0352 68.6069 49.8955 69.064C49.7601 69.5168 49.5633 69.8955 49.3052 70.2002C49.047 70.5091 48.7318 70.7419 48.3594 70.8984C47.987 71.055 47.568 71.1333 47.1025 71.1333C46.6413 71.1333 46.2244 71.055 45.8521 70.8984C45.4797 70.7419 45.1644 70.5091 44.9062 70.2002C44.6439 69.8955 44.4408 69.5168 44.2969 69.064C44.1572 68.6069 44.0874 68.0801 44.0874 67.4834V65.2744C44.0874 64.6777 44.1572 64.153 44.2969 63.7002C44.4365 63.2432 44.6375 62.8602 44.8999 62.5513C45.158 62.2466 45.4712 62.0159 45.8394 61.8594C46.2118 61.7028 46.6286 61.6245 47.0898 61.6245C47.5553 61.6245 47.9743 61.7028 48.3467 61.8594C48.7191 62.0159 49.0365 62.2466 49.2988 62.5513C49.557 62.8602 49.7559 63.2432 49.8955 63.7002C50.0352 64.153 50.105 64.6777 50.105 65.2744V67.4834ZM45.8647 66.7217L48.3276 64.8872C48.3192 64.5402 48.2811 64.244 48.2134 63.9985C48.1499 63.7489 48.0547 63.55 47.9277 63.4019C47.8304 63.2834 47.7098 63.1966 47.5659 63.1416C47.4263 63.0824 47.2676 63.0527 47.0898 63.0527C46.8909 63.0527 46.7153 63.0908 46.563 63.167C46.4149 63.2389 46.29 63.349 46.1885 63.4971C46.0784 63.6536 45.9959 63.8568 45.9409 64.1064C45.8901 64.3561 45.8647 64.6523 45.8647 64.9951V66.0171C45.8647 66.2202 45.8647 66.3556 45.8647 66.4233C45.8647 66.491 45.8647 66.5905 45.8647 66.7217ZM48.3276 67.75V66.6772C48.3276 66.5376 48.3276 66.436 48.3276 66.3726C48.3276 66.3049 48.3276 66.2096 48.3276 66.0869L45.8647 67.915C45.8732 68.1943 45.9007 68.4419 45.9473 68.6577C45.998 68.8735 46.0679 69.0534 46.1567 69.1973C46.2583 69.3708 46.3874 69.4998 46.5439 69.5845C46.7005 69.6691 46.8867 69.7114 47.1025 69.7114C47.3014 69.7114 47.4771 69.6755 47.6294 69.6035C47.7817 69.5273 47.9087 69.4131 48.0103 69.2607C48.116 69.0999 48.1943 68.8947 48.2451 68.645C48.3001 68.3911 48.3276 68.0928 48.3276 67.75Z" />
    <path d="M56.2178 71H54.4341V63.9097L52.0981 64.7476V63.2114L56.1226 61.7578H56.2178V71Z" />
    <path d="M64.0254 71H62.2417V63.9097L59.9058 64.7476V63.2114L63.9302 61.7578H64.0254V71Z" />
  </svg>,
  'processDataIcon'
);
