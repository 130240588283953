import { CSSProperties, FocusEventHandler, Fragment, useRef } from 'react';
import { useTheme } from '@automata/mui-theme';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '../Typography';
import { _castTime, padLeadingZero } from './utils';
import { InputBaseStyled, InputsWrap } from './styled';
import { TimeBreakdown, TimeBreakdownStrings } from './types';
export * from './hooks';

type TimeInput = {
  id: keyof TimeBreakdown;
  placeholder: string;
};

export type DurationPickerV2Props = {
  bgColor?: CSSProperties['backgroundColor'];
  error?: boolean;
  fullWidth?: boolean;
  handleBlurParent?: FocusEventHandler<HTMLDivElement>;
  handleUpdateTime: (timeBreakdownPatch: Partial<TimeBreakdownStrings>) => void;
  hours: string;
  id?: string;
  label?: string;
  minutes: string;
  seconds: string;
};

export const DurationPickerV2 = ({
  bgColor = 'transparent',
  error = false,
  fullWidth = false,
  handleBlurParent,
  handleUpdateTime,
  hours,
  id = `durationPicker-${uuidv4()}`,
  label,
  minutes,
  seconds,
}: DurationPickerV2Props): JSX.Element => {
  const inputsWrapRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  const inputs: TimeInput[] = [
    { id: 'hours', placeholder: 'hh' },
    { id: 'minutes', placeholder: 'mm' },
    { id: 'seconds', placeholder: 'ss' },
  ];

  const breakdown = { hours, minutes, seconds } satisfies TimeBreakdownStrings;

  return (
    <Stack
      onBlur={(e) => {
        const focusIsStillInside = inputsWrapRef.current?.contains(
          e.relatedTarget
        );
        if (focusIsStillInside) {
          e.stopPropagation();
        } else if (handleBlurParent !== undefined) {
          handleBlurParent(e);
        }
      }}
    >
      {label && (
        <Typography
          htmlFor={id}
          component="label"
          variant="overline"
          color={theme.palette.text.primary}
          pt={1}
          pb={0.5}
        >
          {label}
        </Typography>
      )}

      <Typography variant="body2" component="span">
        <InputsWrap
          ref={inputsWrapRef}
          bgcolor={bgColor}
          className={error ? 'error' : undefined}
          maxWidth={fullWidth ? undefined : '10em'}
        >
          {inputs.map(({ id: inputId, placeholder }, i) => {
            const value = breakdown[inputId];
            return (
              <Fragment key={inputId}>
                <InputBaseStyled
                  value={value === '' ? value : padLeadingZero(value)}
                  type="number"
                  sx={
                    // Hide default HTML up/down arrows for type="number"
                    {
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }
                  }
                  slotProps={{ input: { min: 0, max: 99 } }}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    if (_castTime(value) >= 0) {
                      handleUpdateTime({ [inputId]: value });
                    }
                  }}
                  placeholder={placeholder}
                />
                {i < inputs.length - 1 && <span>:</span>}
              </Fragment>
            );
          })}
        </InputsWrap>
      </Typography>
    </Stack>
  );
};
