import { createSvgIcon } from '@automata/mui-theme';
export const labwareIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 4C22 4.55228 21.5523 5 21 5C20.4477 5 20 4.55228 20 4C20 3.44772 20.4477 3 21 3C21.5523 3 22 3.44772 22 4Z" />
    <path d="M22 7C22 7.55228 21.5523 8 21 8C20.4477 8 20 7.55228 20 7C20 6.44772 20.4477 6 21 6C21.5523 6 22 6.44772 22 7Z" />
    <path d="M22 10C22 10.5523 21.5523 11 21 11C20.4477 11 20 10.5523 20 10C20 9.44772 20.4477 9 21 9C21.5523 9 22 9.44772 22 10Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8H4V18.5C4 19.3284 4.67157 20 5.5 20C6.32843 20 7 19.3284 7 18.5V8ZM2 6V18.5C2 20.433 3.567 22 5.5 22C7.433 22 9 20.433 9 18.5V6H2Z"
    />
    <path d="M12 2H17L15.4864 11.0816C15.3007 12.1958 13.6993 12.1958 13.5136 11.0816L12 2Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 15V20H20V15H13ZM11 20C11 21.1046 11.8954 22 13 22H20C21.1046 22 22 21.1046 22 20V15C22 13.8954 21.1046 13 20 13H13C11.8954 13 11 13.8954 11 15V20Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4H4V6H7V4ZM2 2V8H9V2H2Z"
    />
  </svg>,
  'labwareIcon'
);
