import { useCallback, useEffect, useState } from 'react';
import { Close, Refresh } from '@automata/ui';
import { env, logger } from '@automata/utils';
import { useSnackbar } from '@automata/rexports/notistack';
import { snackbars } from 'utils/messages';
import { EnvEnum } from 'utils/envEnum';

interface VersionData {
  version: string;
}

export const useReleaseDiscoverer = (): void => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentVersion, setCurrentVersion] = useState<string>(
    env('NEXT_PUBLIC_VERSION') ?? ''
  );
  const currentEnv = env('NEXT_PUBLIC_ENV');
  const isDevEnv = currentEnv === EnvEnum.DEV || currentEnv === EnvEnum.LOCAL;

  const advertiseNewVersion = useCallback(() => {
    enqueueSnackbar(snackbars.releaseDetected.message, {
      ...snackbars.releaseDetected.options,
      action: (
        <div>
          <Refresh>Reload to use it</Refresh>
          <Close
            onClick={() => {
              closeSnackbar(snackbars.releaseDetected.options.key);
            }}
          >
            Dismiss
          </Close>
        </div>
      ),
    });
  }, [enqueueSnackbar, closeSnackbar]);

  const checkForNewVersion = useCallback(async () => {
    try {
      const buildVersionResponse = await fetch(`/version.json`);
      const buildVersionData: VersionData = await buildVersionResponse.json();
      const buildVersion = buildVersionData.version;

      if (currentVersion !== buildVersion) {
        advertiseNewVersion();
        setCurrentVersion(buildVersion);
      }
    } catch {
      logger.warn('Unable to retrieve build data.');
    }
  }, [currentVersion, advertiseNewVersion]);

  useEffect(() => {
    if (isDevEnv) return;

    const interval = setInterval(() => {
      checkForNewVersion();
    }, 5 * 60 * 1000); // check for new release every 5 minutes

    return () => {
      clearInterval(interval);
    };
  }, [checkForNewVersion, isDevEnv]);
};
