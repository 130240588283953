import { useVariant } from '@automata/utils/hooks';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const UpcomingMaintenanceBanner = (): JSX.Element => {
  const variant = useVariant(
    'fe.informational_maintenance_update_scheduled_for'
  );

  // updateTimestamp is a string in the format '5th March 2025 at 9.00 am GMT'
  const updateTimestamp = variant?.payload?.value;

  return (
    <Alert severity="warning">
      <AlertTitle>
        <strong>Upcoming software update</strong>
      </AlertTitle>
      {updateTimestamp ? (
        <>
          {`A LINQ software update is scheduled for ${updateTimestamp}. Please delay any
        planned workflow runs until after the update is completed.`}
          <div>
            Contact your Customer Success Manager with any questions about this
            update.
          </div>
        </>
      ) : (
        'A periodic software update is ready for release. Please contact your customer success manager to coordinate the date and time of the update.'
      )}
    </Alert>
  );
};
