import type { TimeBreakdown, TimeBreakdownStrings } from './types';

export const padLeadingZero = (s: string): string => s.padStart(2, '0');

export const getTotalSeconds = ({
  hours,
  minutes,
  seconds,
}: TimeBreakdown): number =>
  (hours ?? 0) * 3600 + (minutes ?? 0) * 60 + (seconds ?? 0);

export const _castTime = (s: string): number => {
  const n = Number.parseInt(s, 10);
  if (n >= 0) {
    return n;
  }
  return 0;
};

export const castTimeBreakdownStrings = ({
  hours,
  minutes,
  seconds,
}: TimeBreakdownStrings): TimeBreakdown => {
  return {
    hours: _castTime(hours),
    minutes: _castTime(minutes),
    seconds: _castTime(seconds),
  };
};

export const getTimeBreakdown = (totalSeconds: number): TimeBreakdown => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return { hours, minutes, seconds };
};

export const getTimeBreakdownStrings = (
  totalSeconds: number | null | undefined
): TimeBreakdownStrings => {
  if (
    totalSeconds === null ||
    totalSeconds === undefined ||
    !(totalSeconds >= 0)
  ) {
    return {
      hours: '',
      minutes: '',
      seconds: '',
    };
  }
  const { hours, minutes, seconds } = getTimeBreakdown(totalSeconds);
  return {
    hours: hours.toString(),
    minutes: minutes.toString(),
    seconds: seconds.toString(),
  };
};
