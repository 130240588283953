// Generated from packages/workflow-builder/iconConverter.js
import { createSvgIcon, SvgIconProps } from '@automata/mui-theme';
import { BrokenImageOutlined as BrokenImageOutlinedIcon } from '@automata/mui-theme/icons';
import { logger } from '@automata/utils';
import { memo } from 'react';
import { labwareIcon } from './labwareIcon';
import { processDataIcon } from './processDataIcon';
import { taskIcon } from './taskIcon';
import { taskDependencyIcon } from './taskDependencyIcon';

export { labwareIcon, processDataIcon, taskIcon, taskDependencyIcon };

export const getIcon = (iconName: string): ReturnType<typeof createSvgIcon> => {
  switch (iconName) {
    case 'labware':
      return labwareIcon;
    case 'processdata':
      return processDataIcon;
    case 'task':
      return taskIcon;
    case 'taskdependency':
      return taskDependencyIcon;
    default:
      logger.log(`no icon for iconName ${iconName}`);
      return BrokenImageOutlinedIcon;
  }
};

export const Icon = memo(function Memoized({
  iconType,
  ...otherProps
}: SvgIconProps & { iconType: string }) {
  const Icon = getIcon(iconType.replace(/[-_]/g, ''));
  return <Icon style={{ fill: 'currentColor' }} {...otherProps} />;
});
