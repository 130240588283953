import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@automata/mui-theme';
import { forwardRef } from 'react';

export type { ButtonProps } from '@automata/mui-theme';

export const Button = forwardRef<HTMLButtonElement, MuiButtonProps>(
  function Forwarded(props, ref) {
    return <MuiButton {...props} ref={ref} />;
  }
) as typeof MuiButton;
