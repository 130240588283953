import { createSvgIcon } from '@automata/mui-theme';
export const taskIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14V18H20V14H15ZM14 12C13.4477 12 13 12.4477 13 13V19C13 19.5523 13.4477 20 14 20H21C21.5523 20 22 19.5523 22 19V13C22 12.4477 21.5523 12 21 12H14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 15V7H6V15H12V17H6C4.89543 17 4 16.1046 4 15Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4L7 4V6L16 6V11H18V6C18 4.89543 17.1046 4 16 4Z"
    />
    <path d="M10.415 12L14.6577 16.2426L13.2435 17.6569L9.00083 13.4142L10.415 12Z" />
    <path d="M13 9.41504L17.2426 13.6577L18.6569 12.2435L14.4142 8.00083L13 9.41504Z" />
    <path d="M14.6577 16.2426L10.4146 20.4853L9.00037 19.0711L13.243 14.8285L14.6577 16.2426Z" />
    <path d="M17.2426 13.6577L21.4858 9.41459L20.0716 8.00037L15.829 12.243L17.2426 13.6577Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6ZM5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8Z"
    />
  </svg>,
  'taskIcon'
);
