import { forwardRef } from 'react';
import { Typography } from '../Typography';
import MuiSelect, {
  SelectProps as MuiTextFieldProps,
} from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { v4 as uuidv4 } from 'uuid';
import { defaultTheme } from '@automata/mui-theme';

export type SelectProps = Omit<MuiTextFieldProps, 'size'> & {
  helperText?: string;
  placeholder?: string;
};

export const Select = forwardRef<unknown, SelectProps>(function Forwarded(
  {
    children,
    helperText,
    error,
    id = `select-${uuidv4()}`,
    disabled,
    label,
    value = '',
    placeholder = 'Select an option',
    ...restProps
  },
  ref
) {
  const labelId = `label-${uuidv4()}`;

  return (
    <Stack>
      <Typography
        id={labelId}
        component="label"
        variant="overline"
        color={defaultTheme.palette.text.primary}
        pt={1}
        pb={0.5}
      >
        {label}
      </Typography>
      <FormControl>
        <MuiSelect
          id={id}
          ref={ref}
          labelId={labelId}
          size="small"
          displayEmpty
          value={value}
          defaultValue={value}
          error={error}
          disabled={disabled}
          {...restProps}
        >
          <MenuItem disabled value="">
            <Typography color={defaultTheme.palette.grey[400]}>
              {placeholder}
            </Typography>
          </MenuItem>
          {children}
        </MuiSelect>
        <FormHelperText error={error} disabled={disabled}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
});
