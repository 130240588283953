import { styled } from '@automata/mui-theme';

const StyledSvg = styled('svg')<Pick<Props, 'color' | 'width'>>`
  max-width: ${(p) => p.width};
  path,
  polygon,
  rect {
    fill: ${(p) => p.color};
  }
`;

export interface Props {
  color?: React.CSSProperties['color'];
  width?: string;
}

const LogoLinq = ({
  color = '#022f58',
  width = 'auto',
}: Props): JSX.Element => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 660.27 143.84"
    aria-label="Automata LINQ Logo"
    role="img"
    color={color}
    width={width}
  >
    <g>
      <path d="m127.11,103.8c11.96-6.91,27.22-.59,30.8,12.76,3.58,13.34-6.48,26.45-20.29,26.45H59.21c22.63-13.07,45.27-26.14,67.9-39.2Z" />
      <path d="m42.03,121.98c0,13.81-13.1,23.87-26.45,20.29-13.34-3.58-19.66-18.83-12.76-30.8,13.07-22.63,26.14-45.27,39.2-67.9v78.41Z" />
      <path d="m68.82,39.21c-11.96-6.91-14.12-23.28-4.35-33.05,9.77-9.77,26.14-7.61,33.05,4.35,13.07,22.63,26.14,45.27,39.2,67.9-22.63-13.07-45.27-26.14-67.9-39.2Z" />
    </g>
    <polygon points="230.66 3.5 248.66 3.5 248.66 121.5 317.66 121.5 317.66 139.5 230.66 139.5 230.66 3.5" />
    <rect x="337.66" y="4.84" width="18" height="135" />
    <polygon points="502.91 143.84 398.42 38.2 403.66 39.5 404.04 139.84 385.66 139.84 385.66 .84 386.59 .84 489.77 104.3 485.66 103.39 485.28 4.84 503.66 4.84 503.66 143.84 502.91 143.84" />
    <path d="m592.68,22.43c27.35,0,49.59,22.25,49.59,49.59s-22.25,49.59-49.59,49.59-49.59-22.25-49.59-49.59,22.25-49.59,49.59-49.59m0-18c-37.33,0-67.59,30.26-67.59,67.59s30.26,67.59,67.59,67.59,67.59-30.26,67.59-67.59-30.26-67.59-67.59-67.59Z" />
    <rect x="592.68" y="121.74" width="67.58" height="17.88" />
  </StyledSvg>
);

export default LogoLinq;
