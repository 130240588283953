import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@automata/mui-theme';
import { colors } from '@automata/mui-theme';
import { WorkcellStatus } from '@automata/api/apiSchemas';
import { Typography } from '../..';

const height = 30;
const width = 88;
const localProps = ['backgroundColor', 'borderColor'];

const ChipComponent = styled(Chip, {
  shouldForwardProp: (prop) =>
    typeof prop === 'string' && !localProps.includes(prop),
})<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  width: ${width}px;
  height: ${height}px;
  border-left: 0;
  border-color: ${(p) => p.borderColor} !important;
  background-color: ${(p) => p.backgroundColor};
  transition: none;
`;
export type WorkcellStatusChipProps = {
  workcellStatus: WorkcellStatus;
};

export const WorkcellStatusChip = ({
  workcellStatus,
}: WorkcellStatusChipProps): JSX.Element => {
  const { semantic, grey } = colors;

  const workcellStatusColor = useMemo(() => {
    switch (workcellStatus) {
      case 'offline':
        return grey[700];
      case 'running':
        return semantic.info.main;
      case 'ready':
        return semantic.success.main;
      case 'busy':
        return semantic.info.main;
      case 'error':
        return semantic.error.main;
    }
  }, [
    grey,
    semantic.error.main,
    semantic.info.main,
    semantic.success.main,
    workcellStatus,
  ]);

  return (
    <ChipComponent
      variant="outlined"
      backgroundColor={workcellStatusColor}
      borderColor={workcellStatusColor}
      label={
        <Typography variant="overline" color="white" lineHeight={`${height}px`}>
          {workcellStatus}
        </Typography>
      }
      aria-label="workcell chip"
    />
  );
};
